<template>
  <el-dialog
    id="websiteDialog"
    :visible.sync="editorVisible"
    width="60%"
    :before-close="handleClose"
    top="10px"
    destroy-on-close
  >
    <template slot="title">
      <div style="border-bottom: 1px solid #ccc; padding: 10px; display: flex; align-items: center">
        <input
          v-model="website.title"
          style="font-size: 24px; font-weight: 500; border: none; outline: none; width: 90%"
          :readonly="!isEditMode"
        />
      </div>
      <span> </span>
    </template>
    <BubbleMenu :editor="editor" :tippy-options="{ duration: 100 }" v-if="editor">
      <el-tooltip effect="dark" content="Bold">
        <button
          @click="editor.chain().focus().toggleBold().run()"
          :disabled="!editor.can().chain().focus().toggleBold().run()"
          :class="{ 'is-active': editor.isActive('bold') }"
        >
          <b>B</b>
        </button>
      </el-tooltip>
      <el-tooltip effect="dark" content="Italic">
        <button
          @click="editor.chain().focus().toggleItalic().run()"
          :disabled="!editor.can().chain().focus().toggleItalic().run()"
          :class="{ 'is-active': editor.isActive('italic') }"
        >
          <i>I</i>
        </button>
      </el-tooltip>
      <el-tooltip effect="dark" content="Strike">
        <button
          @click="editor.chain().focus().toggleStrike().run()"
          :disabled="!editor.can().chain().focus().toggleStrike().run()"
          :class="{ 'is-active': editor.isActive('strike') }"
        >
          <s>S</s>
        </button>
      </el-tooltip>
      <!-- <button
        @click="editor.chain().focus().toggleCode().run()"
        :disabled="!editor.can().chain().focus().toggleCode().run()"
        :class="{ 'is-active': editor.isActive('code') }"
      >
        <span>&lt;/&gt;</span>
      </button> -->
      <el-tooltip effect="dark" content="Code Block">
        <button
          @click="editor.chain().focus().toggleCodeBlock().run()"
          :class="{ 'is-active': editor.isActive('codeBlock') }"
        >
          <span>&lt;/&gt;</span>
        </button>
      </el-tooltip>
      <el-tooltip effect="dark" content="Remove format">
        <button alt="Remove format" @click="editor.chain().focus().clearNodes().run()">
          <i style="font-size: 16px" class="el-icon-remove-outline"></i>
        </button>
      </el-tooltip>
      <button
        @click="editor.chain().focus().setParagraph().run()"
        :class="{ 'is-active': editor.isActive('paragraph') }"
      >
        Paragraph
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 1 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }"
      >
        h1
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 2 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }"
      >
        h2
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 3 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }"
      >
        h3
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 4 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }"
      >
        h4
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 5 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }"
      >
        h5
      </button>
      <button
        @click="editor.chain().focus().toggleHeading({ level: 6 }).run()"
        :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }"
      >
        h6
      </button>
      <el-tooltip effect="dark" content="Bullet list">
        <button
          @click="editor.chain().focus().toggleBulletList().run()"
          :class="{ 'is-active': editor.isActive('bulletList') }"
        >
          <img :src="bulletListIcon" />
        </button>
      </el-tooltip>
      <el-tooltip effect="dark" content="Order list">
        <button
          @click="editor.chain().focus().toggleOrderedList().run()"
          :class="{ 'is-active': editor.isActive('orderedList') }"
        >
          <img :src="orderListIcon" />
        </button>
      </el-tooltip>

      <el-tooltip effect="dark" content="Block quote"
        >\

        <button
          @click="editor.chain().focus().toggleBlockquote().run()"
          :class="{ 'is-active': editor.isActive('blockquote') }"
        >
          <img style="width: 15px" :src="quoteIcon" />
        </button>
      </el-tooltip>
      <el-tooltip effect="dark" content="Horizontal Rule">
        <button
          @click="editor.chain().focus().setHorizontalRule().run()"
          style="border-right: 1px solid #ccc; padding-right: 8px"
        >
          <i class="el-icon-minus"></i>
        </button>
      </el-tooltip>
      <button
        @click="editor.chain().focus().undo().run()"
        :disabled="!editor.can().chain().focus().undo().run()"
      >
        <i class="el-icon-back"></i>
      </button>
      <button
        @click="editor.chain().focus().redo().run()"
        :disabled="!editor.can().chain().focus().redo().run()"
      >
        <i class="el-icon-right"></i>
      </button>
    </BubbleMenu>

    <editor-content :editor="editor" />
    <template slot="footer" v-if="isEditMode">
      <el-button @click="handleClose" :isEditorDialogLoading="isEditorDialogLoading"
        >Cancel</el-button
      >
      <el-button @click="handleSaveWebsite" type="primary" :loading="isEditorDialogLoading"
        >Save</el-button
      >
    </template>
    <template slot="footer" v-else>
      <el-button @click="handleClose" :isEditorDialogLoading="isEditorDialogLoading"
        >Close</el-button
      >
    </template>
  </el-dialog>
</template>

<script>
import { Editor, EditorContent, BubbleMenu } from "@tiptap/vue-2";
import StarterKit from "@tiptap/starter-kit";
import Document from "@tiptap/extension-document";
import Paragraph from "@tiptap/extension-paragraph";
import Text from "@tiptap/extension-text";
import Placeholder from "@tiptap/extension-placeholder";

export default {
  props: {
    editorVisible: {
      type: Boolean,
      default: false,
    },
    isEditorDialogLoading: {
      type: Boolean,
      default: false,
    },
    website: {
      type: Object,
      default: () => {
        return {
          title: "",
          content: "",
          tags: {},
        };
      },
    },
    isEditMode: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditorContent,
    BubbleMenu,
  },

  data() {
    return {
      editor: null,
      bulletListIcon: require("@/views/KnowledgeBase/Websites/icons/list.png"),
      orderListIcon: require("@/views/KnowledgeBase/Websites/icons/order-list.png"),
      quoteIcon: require("@/views/KnowledgeBase/Websites/icons/quote.png"),
    };
  },

  methods: {
    handleClose() {
      this.$confirm("Are you sure to close this dialog?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.$emit("updateEditorVisible", { dialog: "editor", status: false });
      });
    },
    handleSaveWebsite() {
      const updatedAriticle = {
        ...this.website,
        tags: JSON.stringify(this.website.tags),
        content: this.editor.getHTML(),
      };
      this.$emit("handleUpdatedWebsite", updatedAriticle);
    },
  },

  mounted() {
    this.editor = new Editor({
      content: `
      ${this.website.content || ""}`,
      editable: this.isEditMode,
      extensions: [
        Document,
        Paragraph,
        Text,
        StarterKit,
        Placeholder.configure({
          placeholder: ({ node }) => {
            return "Can you add some further context?";
          },
        }),
      ],
    });
  },

  beforeDestroy() {
    this.editor.destroy();
  },
};
</script>

<style lang="scss">
/* Basic editor styles */
.tiptap {
  padding: 15px;
  background: #fff;
  color: #000;
  font-size: 16px;
  // line-height: 10px;
  &:focus {
    outline: none;
  }
  height: 80vh;
  overflow-y: scroll;

  /* Define the width and color of the scrollbar track */
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-track {
    background: #cecece;
  }

  /* Define the color and width of the scrollbar thumb (the draggable part) */
  &::-webkit-scrollbar-thumb {
    background: #555;
    border-radius: 6px;
  }

  /* Optional: Add hover effect to the scrollbar thumb */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

#websiteDialog {
  .el-dialog__header {
    // display: none !important;
    padding: 0 !important;
  }
  .el-dialog__body {
    padding: 0 !important;
  }
  .el-dialog__footer {
    padding: 15px;
    border-top: 1px solid #ccc;
  }
}

.editor-menu-button {
  background: #fff;
  margin: 0;
  outline: none;
  border: 0;
  box-sizing: border-box;
}
.tippy-box {
  max-width: 100% !important;
}
.tippy-content {
  div {
    display: flex;
    background: #fff;
    padding: 5px;
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
    border-radius: 5px;
  }
  button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    min-width: 25px;
    background: none;
    border: none;
    margin: 0 2px;

    &:hover {
      cursor: pointer;
    }
    &.is-active {
      background: #eee;
      border-radius: 5px;
    }
  }
  img {
    max-width: 20px;
    max-height: 20px;
  }
}

.tiptap {
  > * + * {
    margin-top: 0.75em;
  }

  ul,
  ol {
    padding: 0 1rem;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    line-height: 1.1;
  }

  code {
    background-color: rgba(#616161, 0.1);
    color: #616161;
  }

  pre {
    background: #0d0d0d;
    color: #fff;
    font-family: "JetBrainsMono", monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img {
    max-width: 100%;
    height: auto;
  }

  blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(#0d0d0d, 0.1);
  }

  hr {
    border: none;
    border-top: 2px solid rgba(#0d0d0d, 0.1);
    margin: 2rem 0;
  }
}
.tiptap .is-empty::before {
  content: attr(data-placeholder);
  float: left;
  color: #ced4da;
  pointer-events: none;
  height: 0;
}
</style>

<template>
  <el-col :sm="16" class="rightPane">
    <el-row>
      <h1>
        <el-tooltip v-if="filterActivated" content="Back to entire list" placement="top">
          <el-button @click="resetChangeSelection" size="mini">
            <i class="el-icon-back" />
          </el-button>
        </el-tooltip>
        <el-tooltip content="Refresh to get latest changes" placement="top">
          <el-button
            size="mini"
            :disabled="isBusy"
            @click="fetchPendingChanges()"
            plain
            icon="el-icon-refresh"
          ></el-button>
        </el-tooltip>

        <el-button size="mini" @click="collapseAll()" plain>Collapse all</el-button>
        <el-button size="mini" @click="expandAll()" plain>Expand all</el-button>
        <span style="text-align: center; margin-left: 15%">Changes Overview</span>

        <div style="float: right" v-if="multipleSelection && multipleSelection.length > 0">
          <!-- This is a placeholder until maker checker on/off is completed -->
          <el-button
            :disabled="selectionErrorList.length > 0 || isBusy || !isAuthorizedToCheck"
            @click="makerCheckerConfiguration.sendEmail ? openRejectConfirmation() : reject()"
            plain
            size="mini"
          >
            Reject Selected ({{ multipleSelection.length }})
          </el-button>
          <el-button
            :disabled="selectionErrorList.length > 0 || isBusy || !isAuthorizedToCheck"
            size="mini"
            @click="
              makerCheckerConfiguration.publish ? openApproveConfirmation() : publishChangesNow()
            "
            type="success"
          >
            Approve Selected ({{ multipleSelection.length }})
          </el-button>
        </div>
      </h1>
    </el-row>
    <el-row>
      <div class="block">
        <el-pagination
          background
          small
          layout="prev, pager, next"
          :pageSize="pageSize"
          :current-page.sync="currentPage"
          :total="noOfItems"
          @prev-click="togglePage"
          @next-click="togglePage"
          @current-change="togglePage"
          @size-change="handleSizeChange"
          style="display: inline-block"
        ></el-pagination>
      </div>
    </el-row>
    <el-row>
      <el-card v-if="multipleSelection.length > 0">
        <span v-if="checkAllSelected">
          All
          <b>{{ multipleSelection.length }}</b> possible change(s) selected
        </span>
        <span v-else>
          Selected
          <b>{{ multipleSelection.length }}</b> change(s)
        </span>

        <el-button size="mini" @click="selectAll">Select Across All Pages</el-button>
        <el-button size="mini" @click="clearAll">Clear All Selections</el-button>
      </el-card>

      <el-card v-if="selectionErrorList.length > 0">
        <div slot="header" class="clearfix">
          <span style="color: orange">Dependency warning</span>
        </div>
        Due to a dependency issue, kindly select the dialog nodes changes highlighted in
        <span style="background-color: oldlace">orange</span>. <br />You have
        <b>{{ filteredSelectionErrorList.length }}</b> pending dialog node change(s) to select.
      </el-card>
    </el-row>
    <el-row style="height: 75vh; overflow-y: scroll">
      <!-- :default-expand-all="true" -->
      <el-table
        v-loading="isBusy"
        element-loading-text="Loading tasks..."
        ref="changesTable"
        highlight-current-row
        @current-change="handleCurrentChange"
        :data="pagedChangesList"
        row-key="RowKey"
        :expand-row-keys="rowExpandData"
        :row-class-name="requiredDialogNodes"
        :default-sort="{ prop: 'last_modified', order: 'descending' }"
        class="changesTable"
        @selection-change="handleSelectionChange"
        style="width: 100%"
        size="mini"
        empty-text="No selected changes"
      >
        <el-table-column
          type="selection"
          :selectable="dependenceCheck"
          class-name="tableSelection"
          min-width="40"
          :reserve-selection="true"
        >
          <!-- <template slot-scope="scope">
              <el-tooltip content="Cannot select as it is not the latest change" placement="left">
                <el-checkbox v-model="changeTableChecked">Option</el-checkbox>
              </el-tooltip>
          </template>-->
        </el-table-column>
        <el-table-column type="expand">
          <template slot-scope="props">
            <JsonDiff
              v-if="props.row.status?.toLowerCase() === 'modify'"
              :old-value="props.row.old_values || {}"
              :new-value="props.row.new_values || {}"
            />

            <table class="changesTable">
              <tr>
                <th><span class="dot old"></span>Old</th>
                <th><span class="dot new"></span>New</th>
              </tr>
              <!-- First row -->
              <tr
                v-if="
                  ['intent', 'example'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  )
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Intent Name:</b>
                  {{ props.row.old_values.intent }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Intent Name:</b>
                  {{ props.row.new_values.intent }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Dialog Node Parent -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  oldOrNewIsNotEmpty('parent_title', props.row.old_values, props.row.new_values) &&
                  (!isEqual(props.row.old_values.parent_title, props.row.old_values.title) ||
                    !isEqual(props.row.new_values.parent_title, props.row.new_values.title))
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Parent Dialog Node:</b>
                  {{ props.row.old_values.parent_title }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Parent Dialog Node:</b>
                  {{ props.row.new_values.parent_title }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Dialog node name -->
              <tr
                v-if="
                  ['dialog'].includes(props.row.type.includes('_') && props.row.type.split('_')[1])
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values) && props.row.old_values.type !== 'folder'">
                  <b>Dialog Node:</b>
                  {{ props.row.old_values.title }}
                </td>
                <td v-else-if="!isEmpty(props.row.old_values)">
                  <b>Folder Name:</b>
                  {{ props.row.old_values.title }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values) && props.row.new_values.type !== 'folder'">
                  <b>Dialog Node:</b>
                  {{ props.row.new_values.title }}
                </td>
                <td v-else-if="!isEmpty(props.row.new_values)">
                  <b>Folder Name:</b>
                  {{ props.row.new_values.title }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Entity changes -->
              <tr
                v-if="
                  ['entity', 'value'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  )
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Entity Name:</b>
                  {{ props.row.old_values.entity }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Entity Name:</b>
                  {{ props.row.new_values.entity }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Editor changes -->
              <tr
                v-if="
                  ['editor'].includes(props.row.type.includes('_') && props.row.type.split('_')[0])
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Node ID:</b>
                  {{ props.row.old_values.id }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Node ID:</b>
                  {{ props.row.new_values.id }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Second rows -->
              <!-- Dialog Node Permissions -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) && oldOrNewIsNotEmpty('description', props.row.old_values, props.row.new_values)
                "
              >
                <!-- Old -->
                <td
                  v-if="
                    !isEmpty(props.row.old_values) &&
                    props.row.old_values.description &&
                    Array.isArray(props.row.old_values.description)
                  "
                >
                  <b>Permissions:</b>
                  {{ props.row.old_values.description.join(",") }}
                </td>
                <td
                  v-else-if="
                    props.row.old_values.description &&
                    !Array.isArray(props.row.old_values.description)
                  "
                >
                  <b>Permissions:</b>
                  {{ props.row.old_values.description }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td
                  v-if="
                    !isEmpty(props.row.new_values) &&
                    props.row.new_values.description &&
                    Array.isArray(props.row.new_values.description)
                  "
                >
                  <b>Permissions:</b>
                  {{ props.row.new_values.description.join(",") }}
                </td>
                <td
                  v-else-if="
                    props.row.new_values.description &&
                    !Array.isArray(props.row.new_values.description)
                  "
                >
                  <b>Permissions:</b>
                  {{ props.row.new_values.description }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : Dialog Node Type -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  oldOrNewIsNotEmpty('type', props.row.old_values, props.row.new_values) &&
                  !isEqual(props.row.old_values.type, props.row.new_values.type)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Node Type:</b>
                  {{ props.row.old_values.type }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Node Type:</b>
                  {{ props.row.new_values.type }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : conditional response number -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) && oldOrNewIsNotEmpty('mcr_number', props.row.old_values, props.row.new_values)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Conditional Response #:</b>
                  {{ props.row.old_values.mcr_number }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Conditional Response #:</b>
                  {{ props.row.new_values.mcr_number }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : slot number -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) && oldOrNewIsNotEmpty('slot_number', props.row.old_values, props.row.new_values)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Slot #:</b>
                  {{ props.row.old_values.slot_number }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Slot #:</b>
                  {{ props.row.new_values.slot_number }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : Event Name -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) && oldOrNewIsNotEmpty('event_name', props.row.old_values, props.row.new_values)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Event Name:</b>
                  {{ props.row.old_values.event_name }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Event Name:</b>
                  {{ props.row.new_values.event_name }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Intent or Entity -->
              <tr
                v-if="
                  ['intent', 'entity'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  )
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Permissions:</b>
                  {{ props.row.old_values.description }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Permissions:</b>
                  {{ props.row.new_values.description }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : Slot - save it as -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  props.row.old_values.type === 'slot' &&
                  oldOrNewIsNotEmpty('variable', props.row.old_values, props.row.new_values)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Save It As:</b>
                  {{ props.row.old_values.variable }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Save It As:</b>
                  {{ props.row.new_values.variable }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : Slot - context [Check for + Save it as]-->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  props.row.old_values.type === 'event_handler' &&
                  oldOrNewIsNotEmpty('context', props.row.old_values, props.row.new_values) &&
                  !isEqual(props.row.old_values.context, props.row.new_values.context)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values) && !isEmpty(props.row.old_values.context)">
                  <b>Check For:</b>
                  {{ props.row.old_values.context[Object.keys(props.row.old_values.context)[0]] }}
                  <br />
                  <br />
                  <b>Save It As:</b>
                  {{
                    Object.keys(props.row.old_values.context)[0]
                      ? Object.keys(props.row.old_values.context)[0]
                      : ""
                  }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values) && !isEmpty(props.row.new_values.context)">
                  <b>Check For:</b>
                  {{ props.row.new_values.context[Object.keys(props.row.new_values.context)[0]] }}
                  <br />
                  <br />
                  <b>Save It As:</b>
                  {{
                    Object.keys(props.row.new_values.context)[0]
                      ? Object.keys(props.row.new_values.context)[0]
                      : ""
                  }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : slot - if not present ask -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  props.row.old_values.event_name &&
                  props.row.old_values.event_name === 'focus' &&
                  oldOrNewIsNotEmpty('output', props.row.old_values, props.row.new_values) &&
                  !isEqual(props.row.old_values.output, props.row.new_values.output)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>If not present, ask:</b>
                  {{ props.row.old_values.output.text }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>If not present, ask:</b>
                  {{ props.row.new_values.output.text }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : slot - found -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  props.row.old_values.event_name &&
                  props.row.old_values.event_name === 'filled' &&
                  oldOrNewIsNotEmpty('output', props.row.old_values, props.row.new_values) &&
                  !isEqual(props.row.old_values.output, props.row.new_values.output)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Found:</b>
                  {{ props.row.old_values.output.text }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Found:</b>
                  {{ props.row.new_values.output.text }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : slot - not found -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  props.row.old_values.event_name &&
                  props.row.old_values.event_name === 'nomatch' &&
                  oldOrNewIsNotEmpty('output', props.row.old_values, props.row.new_values) &&
                  !isEqual(props.row.old_values.output, props.row.new_values.output)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Not Found:</b>
                  {{ props.row.old_values.output.text }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Not Found:</b>
                  {{ props.row.new_values.output.text }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Watson Intent Example  -->
              <tr
                v-if="
                  ['example'].includes(props.row.type.includes('_') && props.row.type.split('_')[1])
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Example:</b>
                  {{ props.row.old_values.text }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Example:</b>
                  {{ props.row.new_values.text }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Watson Entity value -->
              <tr
                v-if="
                  ['value'].includes(props.row.type.includes('_') && props.row.type.split('_')[1])
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Value Name:</b>
                  {{ props.row.old_values.value }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Value Name:</b>
                  {{ props.row.new_values.value }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Editor  -->
              <tr
                v-if="
                  ['editor'].includes(props.row.type.includes('_') && props.row.type.split('_')[0])
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Type:</b>
                  {{ isCard(props.row.old_values) }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Type:</b>
                  {{ isCard(props.row.new_values) }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Third rows -->

              <!-- Entity value -->
              <tr
                v-if="
                  ['value'].includes(props.row.type.includes('_') && props.row.type.split('_')[1])
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Type:</b>
                  {{ props.row.old_values.value_type || props.row.old_values.type }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Type:</b>
                  {{ props.row.new_values.value_type }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Editor value -->
              <tr
                v-if="
                  ['editor'].includes(props.row.type.includes('_') && props.row.type.split('_')[0])
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <el-card style="height: 500px; overflow-y: scroll; box-shadow: none; margin: 0px">
                    <b>Preview:</b>
                    <br />
                    <el-row>
                      <template v-if="props.row.old_values.node.department">
                        <el-tag
                          style="color: $color-info; margin: 3px"
                          size="mini"
                          v-for="department in props.row.old_values.node.department"
                          :key="department"
                          >{{ capitalizeDepartment(department) }}</el-tag
                        >
                      </template>
                    </el-row>
                    <!-- Card -->
                    <template
                      v-if="
                        get(props, 'row.old_values.node.content') &&
                        Array.isArray(get(props, 'row.old_values.node.content'))
                      "
                    >
                      <Card
                        v-for="(card, index) in get(props, 'row.old_values.node.content')"
                        slot="reference"
                        :key="index"
                        :index="index"
                        :card="card"
                        :editable="false"
                      />
                    </template>

                    <!-- Regular content node -->
                    <img
                      v-if="
                        !Array.isArray(get(props, 'row.old_values.node.content')) &&
                        get(props, 'row.old_values.node.content.image')
                      "
                      width="80%"
                      height="80%"
                      :src="get(props, 'row.old_values.node.content.image')"
                      style="max-height: 300px; max-width: 300px"
                    />
                    <MessageBubble
                      v-else-if="
                        !Array.isArray(get(props, 'row.old_values.node.content')) &&
                        checkContentOldNode(props.row.old_values)
                      "
                      v-model="props.row.old_values.node.content"
                    />

                    <QuickReplies
                      v-if="get(props, 'row.old_values.node.quickReplies', []).length !== 0"
                      v-model="props.row.old_values.node.quickReplies"
                      :disabled="true"
                    />
                    <el-divider />
                    <el-collapse v-if="showAdvanced">
                      <el-collapse-item title="JSON" name="1">
                        <!-- <b>JSON:</b> -->
                        <codemirror
                          :options="editorOptions"
                          :value="JSON.stringify(props.row.old_values.node, null, 4)"
                        ></codemirror>
                      </el-collapse-item>
                    </el-collapse>
                  </el-card>
                </td>
                <td v-else></td>

                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <el-card style="height: 500px; overflow-y: scroll; box-shadow: none; margin: 0px">
                    <b>Preview:</b>
                    <br />
                    <el-row>
                      <template v-if="props.row.new_values.node.department">
                        <el-tag
                          style="color: $color-info; margin: 3px"
                          size="mini"
                          v-for="department in props.row.new_values.node.department"
                          :key="department"
                          >{{ capitalizeDepartment(department) }}</el-tag
                        >
                      </template>
                    </el-row>
                    <template
                      v-if="
                        props.row.new_values.node.content &&
                        Array.isArray(props.row.new_values.node.content)
                      "
                    >
                      <Card
                        v-for="(card, index) in props.row.new_values.node.content"
                        :key="index"
                        :index="index"
                        :card="card"
                        :editable="false"
                      />
                    </template>
                    <img
                      v-if="
                        !Array.isArray(get(props, 'row.old_values.node.content')) &&
                        get(props, 'row.old_values.node.content.image')
                      "
                      width="80%"
                      height="80%"
                      :src="get(props, 'row.old_values.node.content.image')"
                      style="max-height: 300px; max-width: 300px"
                    />
                    <MessageBubble
                      v-else-if="
                        !(
                          props.row.new_values.node.content &&
                          Array.isArray(props.row.new_values.node.content)
                        ) && checkContentNewNode(props.row.new_values)
                      "
                      v-model="props.row.new_values.node.content"
                    />
                    <QuickReplies
                      v-if="get(props, 'row.new_values.node.quickReplies', []).length !== 0"
                      v-model="props.row.new_values.node.quickReplies"
                      :disabled="true"
                    />
                    <el-divider />
                    <el-collapse v-if="showAdvanced">
                      <el-collapse-item title="JSON" name="1">
                        <!-- <b>JSON:</b> -->
                        <codemirror
                          :options="editorOptions"
                          :value="JSON.stringify(props.row.new_values.node, null, 4)"
                        ></codemirror>
                      </el-collapse-item>
                    </el-collapse>
                  </el-card>
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : non conditional response -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  (props.row.old_values.type === 'standard' ||
                    props.row.old_values.type === 'folder' ||
                    props.row.old_values.type === 'frame') &&
                  oldOrNewIsNotEmpty('conditions', props.row.old_values, props.row.new_values) &&
                  !isEqual(props.row.old_values.conditions, props.row.new_values.conditions)
                "
              >
                <!-- Old -->
                <td
                  v-if="
                    !isEmpty(props.row.old_values) &&
                    props.row.old_values.conditions &&
                    props.row.old_values.conditions != ''
                  "
                >
                  <b>Bot recognises:</b>
                  <table style="width: 100%" border="1" id="dialog-condition-table">
                    <tr>
                      <th class="dialog-condition-table-operator">Operator</th>
                      <th class="dialog-condition-table-text">Conditions</th>
                    </tr>
                    <tr
                      v-for="condition in props.row.old_values.conditionArray"
                      :key="condition.text"
                    >
                      <td
                        class="dialog-condition-table-operator"
                        v-if="condition.condition == '&&'"
                      >
                        AND
                      </td>
                      <td
                        class="dialog-condition-table-operator"
                        v-else-if="condition.condition == '||'"
                        style="color: grey"
                      >
                        OR
                      </td>
                      <td
                        class="dialog-condition-table-operator"
                        v-else-if="props.row.old_values.conditionArray.length === 1"
                        style="color: grey"
                      >
                        NA
                      </td>
                      <td class="dialog-condition-table-operator" v-else></td>
                      <td class="dialog-condition-table-text">
                        {{ condition.text }}
                      </td>
                    </tr>
                  </table>
                </td>
                <td v-else></td>
                <!-- New -->
                <td
                  v-if="
                    !isEmpty(props.row.new_values) &&
                    props.row.new_values.conditions &&
                    props.row.new_values.conditions != ''
                  "
                >
                  <b>Bot recognises:</b>
                  <table style="width: 100%" border="1" class="dialog-condition-table">
                    <tr>
                      <th class="dialog-condition-table-operator">Operator</th>
                      <th class="dialog-condition-table-text">Conditions</th>
                    </tr>
                    <tr
                      v-for="condition in props.row.new_values.conditionArray"
                      :key="condition.text"
                    >
                      <td
                        class="dialog-condition-table-operator"
                        v-if="condition.condition == '&&'"
                      >
                        AND
                      </td>
                      <td
                        class="dialog-condition-table-operator"
                        v-else-if="condition.condition == '||'"
                        style="color: grey"
                      >
                        OR
                      </td>
                      <td
                        class="dialog-condition-table-operator"
                        v-else-if="props.row.new_values.conditionArray.length === 1"
                        style="color: grey"
                      >
                        NA
                      </td>
                      <td class="dialog-condition-table-operator" v-else></td>
                      <td class="dialog-condition-table-text">
                        {{ condition.text }}
                      </td>
                    </tr>
                  </table>
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : conditional response -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  props.row.old_values.type !== 'standard' &&
                  props.row.old_values.type !== 'folder' &&
                  props.row.old_values.type !== 'frame' &&
                  props.row.old_values.type === 'response_condition' &&
                  oldOrNewIsNotEmpty('conditions', props.row.old_values, props.row.new_values) &&
                  !isEqual(props.row.old_values.conditions, props.row.new_values.conditions)
                "
              >
                <!-- Old -->
                <td
                  v-if="
                    !isEmpty(props.row.old_values) &&
                    props.row.old_values.conditions &&
                    props.row.old_values.conditions != ''
                  "
                >
                  <b>Bot recognises:</b>
                  <br />
                  {{ props.row.old_values.conditions }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td
                  v-if="
                    !isEmpty(props.row.new_values) &&
                    props.row.new_values.conditions &&
                    props.row.new_values.conditions != ''
                  "
                >
                  <b>Bot recognises:</b>
                  <br />
                  {{ props.row.new_values.conditions }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Fourth Row -->

              <!-- Watson entity -->
              <tr
                v-if="
                  ['value'].includes(props.row.type.includes('_') && props.row.type.split('_')[1])
                "
              >
                <!-- Old -->
                <td
                  v-if="
                    !isEmpty(props.row.old_values) &&
                    (props.row.old_values.value_type === 'synonyms' ||
                      props.row.old_values.type === 'synonyms')
                  "
                >
                  <b>Synonyms:</b>
                  {{ props.row.old_values.synonyms }}
                </td>
                <td
                  v-else-if="
                    !isEmpty(props.row.old_values) &&
                    (props.row.old_values.value_type === 'patterns' ||
                      props.row.old_values.type === 'patterns')
                  "
                >
                  <b>Patterns:</b>
                  {{ props.row.old_values.patterns }}
                </td>
                <td v-else></td>

                <!-- New -->
                <td
                  v-if="
                    !isEmpty(props.row.new_values) &&
                    (props.row.new_values.value_type === 'synonyms' ||
                      props.row.new_values.type === 'synonyms')
                  "
                >
                  <b>Synonyms:</b>
                  {{ props.row.new_values.synonyms }}
                </td>
                <td
                  v-else-if="
                    !isEmpty(props.row.new_values) &&
                    (props.row.new_values.value_type === 'patterns' ||
                      props.row.new_values.type === 'patterns')
                  "
                >
                  <b>Patterns:</b>
                  {{ props.row.new_values.patterns }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Dialog Node Context -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  oldOrNewIsNotEmpty('context', props.row.old_values, props.row.new_values) &&
                  !isEqual(props.row.old_values.contextArray, props.row.new_values.contextArray)
                "
              >
                <!-- Old -->
                <td
                  v-if="
                    !isEmpty(props.row.old_values) &&
                    props.row.old_values.contextArray &&
                    !isEmpty(props.row.old_values.context)
                  "
                >
                  <b>Context:</b>
                  <br />
                  <table style="width: 100%" id="dialog-context-table" border="1">
                    <tr>
                      <th class="dialog-context-table-variable">Variables</th>
                      <th class="dialog-context-table-value">Value</th>
                    </tr>
                    <tr v-for="(context, index) in props.row.old_values.contextArray" :key="index">
                      <td class="dialog-context-table-variable">
                        {{ context.key }}
                      </td>
                      <td class="dialog-context-table-value">
                        {{ context.value }}
                      </td>
                    </tr>
                  </table>
                </td>
                <td v-else></td>
                <!-- New -->
                <td
                  v-if="
                    !isEmpty(props.row.new_values) &&
                    props.row.new_values.contextArray &&
                    !isEmpty(props.row.new_values.context)
                  "
                >
                  <b>Context:</b>
                  <br />
                  <table style="width: 100%" class="dialog-context-table" border="1">
                    <tr>
                      <th class="dialog-context-table-variable">Variables</th>
                      <th class="dialog-context-table-value">Value</th>
                    </tr>
                    <tr v-for="(context, index) in props.row.new_values.contextArray" :key="index">
                      <td class="dialog-context-table-variable">
                        {{ context.key }}
                      </td>
                      <td class="dialog-context-table-value">
                        {{ context.value }}
                      </td>
                    </tr>
                  </table>
                </td>
                <td v-else></td>
              </tr>

              <!-- Dialog Node Responses -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  oldOrNewIsNotEmpty(
                    'output.generic[0].values[0].text',
                    props.row.old_values,
                    props.row.new_values
                  ) &&
                  !isEqual(props.row.old_values.output, props.row.new_values.output)
                "
              >
                <!-- Old -->
                <td
                  v-if="
                    !isEmpty(props.row.old_values) &&
                    props.row.old_values.output &&
                    props.row.old_values.output.generic &&
                    props.row.old_values.output.generic.length > 0
                  "
                >
                  <div
                    v-for="(response, index) in props.row.old_values.output.generic"
                    :key="index"
                  >
                    <b>Response #{{ index + 1 }}:</b>
                    <br />
                    <!-- {{response.response_type}} <br> -->
                    <table id="dialog-response-table" style="width: 100%" border="1">
                      <tr>
                        <th class="dialog-response-table-type">Type</th>
                        <th>Value</th>
                      </tr>

                      <tr v-for="value in response.values" :key="value.text">
                        <td v-if="value.text" class="dialog-response-table-type">
                          <span v-if="contentNodeExists(value.text)">Content node</span>
                          <span v-else>Plain text</span>
                        </td>
                        <td v-if="value.text" style="text-align: center">
                          {{ value.text }}
                          <br />
                          <MessageBubble
                            v-if="contentNodeExists(value.text)"
                            v-model="publishContentNodeObject[value.text].content"
                          />
                          <QuickReplies
                            v-if="contentNodeExists(value.text)"
                            v-model="publishContentNodeObject[value.text].quickReplies"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
                <td v-else></td>
                <!-- New -->
                <td
                  v-if="
                    !isEmpty(props.row.new_values) &&
                    props.row.new_values.output &&
                    props.row.new_values.output.generic &&
                    props.row.new_values.output.generic.length > 0
                  "
                >
                  <div
                    v-for="(response, index) in props.row.new_values.output.generic"
                    :key="index"
                  >
                    <b>Response #{{ index + 1 }}:</b>
                    <!-- {{response.response_type}} <br> -->
                    <!-- <b>Content Node ID:</b> <br>
                    <span v-for="(val) in res.values" :key="val.text"> {{val.text}} <br></span>-->
                    <table class="dialog-response-table" style="width: 100%" border="1">
                      <tr>
                        <th class="dialog-response-table-type">Type</th>
                        <th>Value</th>
                      </tr>

                      <tr v-for="value in response.values" :key="value.text">
                        <td
                          v-if="value.text && value.text != ''"
                          class="dialog-response-table-type"
                        >
                          <span v-if="contentNodeExists(value.text)">Content node</span>
                          <span v-else>Plain text</span>
                        </td>
                        <td v-if="value.text && value.text != ''" style="text-align: center">
                          {{ value.text }}
                          <br />
                          <MessageBubble
                            v-if="contentNodeExists(value.text)"
                            v-model="publishContentNodeObject[value.text].content"
                          />
                          <QuickReplies
                            v-if="contentNodeExists(value.text)"
                            v-model="publishContentNodeObject[value.text].quickReplies"
                          />
                        </td>
                      </tr>
                    </table>
                  </div>
                </td>
                <td v-else></td>
              </tr>

              <!-- Final Action -->
              <!-- Watson Dialog -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  oldOrNewIsNotEmpty('next_step', props.row.old_values, props.row.new_values) &&
                  !isEqual(props.row.old_values.next_step, props.row.new_values.next_step)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values) && props.row.old_values.next_step">
                  <b>Final Action:</b>
                  {{ props.row.old_values.next_step.behavior }}
                </td>
                <td v-else><b>Final Action:</b> Wait for input</td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values) && props.row.new_values.next_step">
                  <b>Final Action:</b>
                  {{ props.row.new_values.next_step.behavior }}
                </td>
                <td v-else><b>Final Action:</b> Wait for input</td>
              </tr>

              <!-- Parmeshwar : Digress In -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  oldOrNewIsNotEmpty('digress_in', props.row.old_values, props.row.new_values) &&
                  !isEqual(props.row.old_values.digress_in, props.row.new_values.digress_in)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Digress In:</b>
                  {{ props.row.old_values.digress_in }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Digress In:</b>
                  {{ props.row.new_values.digress_in }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : Digress Out -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  oldOrNewIsNotEmpty('digress_out', props.row.old_values, props.row.new_values) &&
                  !isEqual(props.row.old_values.digress_out, props.row.new_values.digress_out)
                "
              >
                <!-- Old -->
                <td v-if="!isEmpty(props.row.old_values)">
                  <b>Digress Out:</b>
                  {{ props.row.old_values.digress_out }}
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="!isEmpty(props.row.new_values)">
                  <b>Digress Out:</b>
                  {{ props.row.new_values.digress_out }}
                </td>
                <td v-else></td>
              </tr>

              <!-- Parmeshwar : Metadata to show mcr flag value changed -->
              <tr
                v-if="
                  ['dialog'].includes(
                    props.row.type.includes('_') && props.row.type.split('_')[1]
                  ) &&
                  oldOrNewIsNotEmpty(
                    'metadata._customization',
                    props.row.old_values,
                    props.row.new_values
                  ) &&
                  props.row.old_values.metadata &&
                  props.row.new_values.metadata &&
                  !isEqual(
                    props.row.old_values.metadata._customization,
                    props.row.new_values.metadata._customization
                  )
                "
              >
                <!-- Old -->
                <td v-if="props.row.old_values.metadata._customization !== '{}'">
                  <b>Metadata:</b>
                  <br />
                  <table class="dialog-response-table" style="width: 100%" border="1">
                    <tr>
                      <th>Type</th>
                      <th>Value</th>
                    </tr>
                    <tr
                      v-for="(value, key) in props.row.old_values.metadata._customization"
                      :key="key"
                    >
                      <td style="text-align: center">{{ key }}</td>
                      <td style="text-align: center">{{ value }}</td>
                    </tr>
                  </table>
                </td>
                <td v-else></td>
                <!-- New -->
                <td v-if="props.row.new_values.metadata._customization !== '{}'">
                  <b>Metadata:</b>
                  <br />
                  <table class="dialog-response-table" style="width: 100%" border="1">
                    <tr>
                      <th>Type</th>
                      <th>Value</th>
                    </tr>
                    <tr
                      v-for="(value, key) in props.row.new_values.metadata._customization"
                      :key="key"
                    >
                      <td style="text-align: center">{{ key }}</td>
                      <td style="text-align: center">{{ value }}</td>
                    </tr>
                  </table>
                </td>
                <td v-else></td>
              </tr>
            </table>
            <JSONEditor v-if="showAdvanced" v-model="props.row" />
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Status" min-width="100px" sortable>
          <template slot-scope="slot">
            <!-- {{slot.row.status}} -->
            <div v-if="slot.row.status.includes('add')">
              <span class="has-text-success">Added</span>
            </div>
            <div v-else-if="slot.row.status.includes('delete')">
              <span class="has-text-danger">Deleted</span>
            </div>
            <div v-else-if="slot.row.status.includes('approve')">
              <span class="has-text-success">Approved</span>
            </div>
            <div v-else-if="slot.row.status.includes('reject')">
              <span class="has-text-danger">Rejected</span>
            </div>
            <div v-else-if="slot.row.status.includes('modify')">
              <span class="has-text-warning">Modified</span>
            </div>
          </template>
          <!-- Change status to modified and add -->
        </el-table-column>
        <el-table-column prop="type" label="Type" min-width="115px" sortable></el-table-column>
        <el-table-column
          prop="last_modified"
          label="Date & Time"
          min-width="160px"
          sortable
        ></el-table-column>
        <el-table-column
          prop="modified_by"
          label="Editor Name"
          show-overflow-tooltip
          min-width="200px"
          sortable
        ></el-table-column>
      </el-table>
    </el-row>
    <!-- Reject Confirmation dialog -->
    <RejectChangesDialog
      v-if="showRejectConfirmationForm"
      :activeDepartment="activeDepartment"
      :filteredDepartments="filteredDepartments"
      :multipleSelection="multipleSelection"
      :specificChange="specificChange"
      :activeTaskType="activeTaskType"
      :filterActivated="filterActivated"
      :data-filter="dataFilter"
      :editorOptions="editorOptions"
      :editorHTMLOptions="editorHTMLOptions"
      :data-user-filter="dataUserFilter"
      @resetChangeSelection="resetChangeSelection"
      @fetchCompletedChanges="fetchCompletedChanges"
      @fetchPendingChanges="fetchPendingChanges"
      @closeApprove="closeApprove"
    ></RejectChangesDialog>

    <!-- Approval Confirmation dialog -->
    <ApproveChangesDialog
      v-if="approveConfirmationVisible"
      :activeDepartment="activeDepartment"
      :filteredDepartments="filteredDepartments"
      :multipleSelection="multipleSelection"
      :specificChange="specificChange"
      :activeTaskType="activeTaskType"
      :filterActivated="filterActivated"
      :data-filter="dataFilter"
      @resetChangeSelection="resetChangeSelection"
      @fetchCompletedChanges="fetchCompletedChanges"
      @fetchPendingChanges="fetchPendingChanges"
      @closeApprove="closeApprove"
    ></ApproveChangesDialog>
  </el-col>
</template>
<script>
import _ from "lodash";
import { mapGetters } from "vuex";
import { pickerOptionsForFutureDatesVersionII } from "@/helperMethods/util";
import ApproveChangesDialog from "./ApproveChangesDialog";
import MessageBubble from "@/components/Bubble";
import Card from "@/components/Card";
import JSONEditor from "@/components/JSONEditor";
import QuickReplies from "@/components/QuickReplies";
import RejectChangesDialog from "./RejectChangesDialog";
import JsonDiff from "@/components/JsonDiff/JsonDiff";
import Vue from "vue";

export default Vue.extend({
  components: {
    JSONEditor,
    JsonDiff,
    MessageBubble,
    QuickReplies,
    Card,
    ApproveChangesDialog,
    RejectChangesDialog,
  },
  props: [
    "searchTerm",
    "isAuthorizedToCheck",
    "dependenceMap",
    "changesMap",
    "overallDependenceMap",
    "dialogChangesMap",
    "fetchCompletedChanges",
    "fetchPendingChanges",
    "specificChange",
    "filteredChangesList",
    "activeDepartment",
    "isBusy",
  ],
  data() {
    return {
      // Filter set up
      isCollapsed: false,

      // Filter by department
      filteredDepartments: [],

      // Filter by active task type
      activeTaskType: [],

      multipleSelection: [],

      // Admin Console
      editorOptions: {
        mode: "text/javascript",
        value: "",
        title: "JSON",
        highlightDifferences: true,
        tabSize: 4,
        readOnly: true,
        smartIndent: true,
        lineWrapping: true,
      },
      editorHTMLOptions: {
        mode: "text/html",
        value: "",
        title: "Email Preview",
        highlightDifferences: true,
        tabSize: 4,
        readOnly: true,
        smartIndent: true,
        lineWrapping: true,
        tags: {
          style: [
            ["type", /^text\/(x-)?scss$/, "text/x-scss"],
            [null, null, "css"],
          ],
        },
      },
      rowExpandData: [],

      // Approve Confirmation
      approveConfirmationForm: { publish_date: "", publishName: "" },
      approveConfirmationVisible: false,
      approveConfirmationLoading: false,
      selectedApprove: {
        department: "",
        mainType: "",
      },
      approveConfirmationRule: {
        publish_date: [
          {
            required: true,
            message: "Please input date and time",
            trigger: "blur",
          },
        ],
      },
      dateTimePicker: pickerOptionsForFutureDatesVersionII,

      // Reject Confirmation
      showRejectConfirmationForm: false,
      selectedReject: {
        department: "",
        mainType: "",
      },
      rejectConfirmationForm: {
        from: "",
        to: "",
        cc: "",
        name: "",
        message: "",
      },
      rejectConfirmationRule: {
        message: [{ required: true, message: "A comment for user is required" }],
      },

      emailPreview: "",
      emailPreviewFetched: false,

      // Changes dependency setup
      // dependenceMap: new Map(), // Map the latest change to it's depedency changes
      // changesMap: new Map(),
      // overallDependenceMap: new Map(), // To map each and every changes to their latest change
      publishContentNodeObject: {},
      // dialogChangesMap: new Map(), // to find out the earlier changes to every change itself
      selectionErrorList: [],
      filteredSelectionErrorList: [],

      selectedNewNode: {},
      pageSize: 10,
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters([
      "pendingChangesList",
      "completedChangesList",
      "formattedDepartments",
      "getUserAuthentication",
      "makerCheckerObject",
      "departments",
      "showAdvanced",
    ]),
    checkAllSelected() {
      const selectableRows = _.filter(this.filteredChangesList, (row) => {
        return this.dependenceMap.has(row.RowKey);
      });
      return selectableRows.length == this.$refs.changesTable.selection.length;
    },
    dialogNodesChosen() {
      if (!this.multipleSelection) {
        return false;
      }

      const tempDialogNodeArray = _.filter(this.multipleSelection, (selection) => {
        return selection.mainType === "dialog";
      });
      const dialogChosen = tempDialogNodeArray.length > 0;
      return dialogChosen;
    },
    filterActivated() {
      return (
        !_.isEmpty(this.specificChange) ||
        !_.isEmpty(this.activeDepartment) ||
        !_.isEmpty(this.searchTerm) ||
        !_.isEmpty(this.activeTaskType)
      );
    },

    makerCheckerConfiguration() {
      return _.assign(this.makerCheckerObject, {
        filterActivated: this.filterActivated,
      });
    },

    noOfItems() {
      this.currentPage = 1;
      return this.filteredChangesList.length;
    },
    pagedChangesList: {
      get() {
        const startingIndex = (this.currentPage - 1) * this.pageSize;
        const currentIndex = this.currentPage * this.pageSize;
        const totalRecords = this.filteredChangesList.length;
        const pagedChanges = this.filteredChangesList.slice(
          startingIndex,
          totalRecords >= currentIndex ? currentIndex : totalRecords
        );
        return pagedChanges;
      },
      set(changesList) {},
    },
  },
  methods: {
    selectAll() {
      this.filteredChangesList.forEach((row) => {
        const rowIsDependentAndNotAlreadySelected =
          this.dependenceMap.has(row.RowKey) && !this.$refs.changesTable.selection.includes(row);
        if (rowIsDependentAndNotAlreadySelected) {
          this.$refs.changesTable.toggleRowSelection(row);
        }
      });
    },
    clearAll() {
      this.filteredChangesList.forEach((row) => {
        const rowIsDependentAndAlreadySelected =
          this.dependenceMap.has(row.RowKey) && this.$refs.changesTable.selection.includes(row);
        if (rowIsDependentAndAlreadySelected) {
          this.$refs.changesTable.toggleRowSelection(row);
        }
      });
    },
    closeApprove() {
      this.approveConfirmationVisible = false;
      this.showRejectConfirmationForm = false;
    },
    togglePage(pageNo) {
      this.currentPage = pageNo;
      this.refreshTable();
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.refreshTable();
    },
    refreshTable() {
      const startingIndex = (this.currentPage - 1) * this.pageSize;
      const currentIndex = this.currentPage * this.pageSize;
      const totalRecords = this.filteredChangesList.length;
      this.pagedChangesList = this.filteredChangesList.slice(
        startingIndex,
        totalRecords >= currentIndex ? currentIndex : totalRecords
      );
    },
    getUniqueChangeTypesOfAuthorisedDepartments(changesList, departments) {
      return _.chain(changesList)
        .filter((change) => ["entity", "intent", "dialog", "editor"].includes(change.mainType))
        .filter((change) => {
          // Filter by department
          let changeDepartment = change.department;
          let changeDepartmentArr = [];

          const changeHasNoDepartments = !(departments && changeDepartment);
          if (changeHasNoDepartments) {
            return true;
          }

          const firstElementIsString = typeof _.get(changeDepartment, "[0]") === "string";
          const moreThanOneDepartment = changeDepartment[0].includes(",");

          if (firstElementIsString && moreThanOneDepartment)
            changeDepartmentArr = changeDepartment[0].split(",");
          else if (typeof changeDepartment === "string") {
            changeDepartmentArr = [changeDepartment];
          } else {
            changeDepartmentArr = changeDepartment;
          }

          changeDepartmentArr = _.chain(changeDepartmentArr)
            .compact()
            .flattenDeep()
            .map((department) => department && department.toLowerCase())
            .value();

          const userIsAuthorized =
            _.intersection(departments, changeDepartmentArr).length > 0 ||
            changeDepartmentArr.includes("general");

          return userIsAuthorized;
        })
        .value();
    },
    capitalizeDepartment(nodeDepartment) {
      if (nodeDepartment && typeof nodeDepartment === "string") {
        return _.chain(nodeDepartment.split(" "))
          .map((d) => _.capitalize(d))
          .value()
          .join(" ");
      }
    },
    checkContentOldNode(node) {
      const oldNode = node;
      if (!oldNode.node || !oldNode.node.content) {
        return false;
      }
      return true;
    },
    checkContentNewNode(node) {
      const newNode = node;
      if (!newNode.node || !newNode.node.content) {
        return false;
      }
      return true;
    },
    contentNodeExists(content_node_id) {
      const contentNode = this.$store.state.nodes.content[content_node_id];
      if (contentNode) {
        this.publishContentNodeObject[content_node_id] = contentNode;
        return true;
      } else {
        return false;
      }
    },
    isEqual: _.isEqual,
    isEmpty: _.isEmpty,
    get: _.get,
    oldOrNewIsNotEmpty(path, old_values, new_values) {
      const old_values_variable = _.get(old_values, path, {});
      const new_values_variable = _.get(new_values, path, {});

      return !this.isEmpty(old_values_variable) || !this.isEmpty(new_values_variable);
    },

    publishContent() {
      this.$emit("update:isBusy", true);
      this.$store
        .dispatch("PUBLISH_EDITOR_CONTENT")
        .then((isPublished) => {
          if (isPublished) {
            this.$set(this.$store.state.makerChecker, "isBusy", false);
            this.fetchPendingChanges();
            this.fetchCompletedChanges();
            this.resetChangeSelection();
            this.$message({
              type: "success",
              message: "All DEV Changes has been published to PROD successfully",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error publishing content.",
          });

          this.$set(this.$store.state.makerChecker, "isBusy", false);
        });
    },
    typeFilter(type, department) {
      if (department === "all") {
        return [type.toLowerCase()];
      }
      if (_.get(this, "multipleSelection.length") === 0) {
        return [];
      }

      return _.chain(this.multipleSelection)
        .map((change) => {
          return change.mainType && change.mainType.toLowerCase();
        })
        .uniq()
        .value();
    },
    departmentsFilter(type, department) {
      const userDepartments = this.departments || [];
      const activeDepartment = this.activeDepartment || "";
      const lowerCaseActiveDepartment = [activeDepartment].map((v) => v.toLowerCase());

      const allDeparmentsSelected =
        department === "all" ||
        !department ||
        lowerCaseActiveDepartment.includes("all departments");

      if (allDeparmentsSelected) {
        return userDepartments;
      }

      if (lowerCaseActiveDepartment) {
        const userValidatedDeparments = _.intersection(activeDepartment, userDepartments);
        return userValidatedDeparments;
      }
    },
    dataFilter(type, department) {
      const dependenceMap = this.dependenceMap;

      if (department === "all") {
        return this.getUniqueChangeTypesOfAuthorisedDepartments(
          this.pendingChangesList,
          this.filteredDepartments
        ).filter((change) => {
          return change.mainType === type;
        });
      }

      if (this.multipleSelection && this.multipleSelection.length > 0)
        return _.chain(this.multipleSelection)
          .map((change) => {
            let tempArray = [];
            if (dependenceMap.has(change.RowKey)) {
              const dependentChange = dependenceMap.get(change.RowKey);
              const dependenceArr = dependentChange.dependence;

              const latestChange = this.changesMap.get(change.RowKey);
              tempArray.push(latestChange); // Push the latest change first

              this.dependenceMap.delete(change.RowKey);

              dependenceArr &&
                dependenceArr.map((rowKey) => {
                  const tempChange = this.changesMap.get(rowKey);
                  tempArray.push(tempChange); // Push other previous changes of same ID
                });
            }
            return tempArray;
          })
          .flattenDeep()
          .orderBy(["last_modified"], ["desc"])
          .value();
    },
    resolverDataHandover(type, department) {
      const result = {
        listOfChanges: this.dataFilter(type, department),
        types: this.typeFilter(type, department),
        departments: this.departmentsFilter(type, department),
      };
      return result;
    },
    publishChangesNow() {
      const publishEnabled = _.get(this.makerCheckerConfiguration, "publish", false);
      if (publishEnabled) {
        this.approveConfirmationVisible = true;
        this.approveConfirmationLoading = true;
      }

      const { mainType: type, department } = this.selectedApprove;
      const { publish_date, publishName } = this.approveConfirmationForm;
      const { listOfChanges, types, departments } = this.resolverDataHandover(type, department);

      const payload = {
        publish_date,
        publishName,
        listOfChanges,
        types,
        departments,
      };
      this.$store
        .dispatch("CREATE_AND_COMPLETE_PUBLISH", payload)
        .then((isPublished) => {
          if (isPublished) {
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$message({
              type: "success",
              message: "Changes has been approved and published successfully",
            });
            this.fetchPendingChanges();
            this.fetchCompletedChanges();
            this.resetChangeSelection();
          } else {
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$message({
              type: "error",
              message: "Encountered error trying to approve changes",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error creating and publish content.",
          });
          this.approveConfirmationLoading = false;
          this.approveConfirmationVisible = false;
        });
    },
    publishChangesOnDate() {
      this.approveConfirmationVisible = true;
      this.approveConfirmationLoading = true;

      const { mainType: type, department } = this.selectedApprove;
      const { publish_date, publishName } = this.approveConfirmationForm;
      const { listOfChanges, types, departments } = this.resolverDataHandover(type, department);
      const payload = {
        publish_date,
        publishName,
        listOfChanges,
        types,
        departments,
      };

      this.$store
        .dispatch("CREATE_PUBLISH", payload)
        .then((isPublished) => {
          if (isPublished) {
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$message({
              type: "success",
              message: "Changes has been approved and added to publish queue successfully",
            });
            this.fetchPendingChanges();
            this.fetchCompletedChanges();
            this.resetChangeSelection();
          } else {
            this.approveConfirmationLoading = false;
            this.approveConfirmationVisible = false;
            this.$message({
              type: "error",
              message: "Encountered error trying to approve and create new publications",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error creating a publish.",
          });
          this.approveConfirmationLoading = false;
          this.approveConfirmationVisible = false;
        });
    },

    openRejectConfirmation(mainType, department) {
      this.showRejectConfirmationForm = true;
      this.$store.commit("SET_MAIN_TYPE", mainType);
      this.$store.commit("SET_DEPARTMENT_TYPE", department);
    },
    openApproveConfirmation(mainType, department) {
      this.approveConfirmationVisible = true;
      this.$store.commit("SET_MAIN_TYPE", mainType);
      this.$store.commit("SET_DEPARTMENT_TYPE", department);
    },
    handleCurrentChange(row) {
      if (row) {
        this.$refs.changesTable.setCurrentRow(
          this.changesMap.get(this.overallDependenceMap.get(row.RowKey))
        );
      }
    },
    dependenceCheck(row, index) {
      return this.dependenceMap.has(row.RowKey);
    },
    handleSelectionChange(newArrayOfSelection) {
      newArrayOfSelection = _.sortBy(newArrayOfSelection, ["last_modified"], ["desc"]);

      this.multipleSelection = newArrayOfSelection;
      this.selectionErrorList = [];
      let tempSelectionErrorArray = [];

      newArrayOfSelection.forEach((value) => {
        const { RowKey } = value;
        const dependentDialogChanges = this.dialogChangesMap.get(RowKey);
        tempSelectionErrorArray.push(dependentDialogChanges);
        tempSelectionErrorArray = _.chain(tempSelectionErrorArray).flattenDeep().uniq().value();
        if (dependentDialogChanges) {
          this.selectionErrorList = _.intersection(dependentDialogChanges, tempSelectionErrorArray);
        }
      });
    },
    requiredDialogNodes({ row, rowIndex }) {
      const selectionErrorList = this.selectionErrorList;
      const multipleSelectionRowKey = this.multipleSelection.map((select) => select.RowKey);
      const filteredSelectionErrorList =
        _.pullAll(selectionErrorList, multipleSelectionRowKey) || [];
      this.filteredSelectionErrorList = filteredSelectionErrorList;
      if (
        !_.isEmpty(filteredSelectionErrorList) &&
        filteredSelectionErrorList.indexOf(row.RowKey) !== -1
      ) {
        return "warning-row";
      }
      return "";
    },

    resetChangeSelection() {
      this.collapseAll();
      this.activeDepartment = "";
      this.activeTaskType = [];
      this.$emit("resetChangeSelection");
    },

    isCard(value) {
      return Array.isArray(value.node.content) ? "Card" : "Text Bubble";
    },

    collapseAll() {
      this.isCollapsed = true;
      this.rowExpandData = [];
    },
    expandAll() {
      this.isCollapsed = false;
      this.rowExpandData = this.filteredChangesList.map((item) => item.RowKey);
    },
    reject(type, department, date) {
      // this.isBusy = true;
      this.$emit("update:isBusy", true);
      const { listOfChanges, types, departments } = this.resolverDataHandover(type, department);

      const payload = {
        type: types,
        date,
        departments,
        customListOfChanges: listOfChanges,
      };

      this.$store
        .dispatch("REJECT_CHANGES", payload)
        .then((isRejected) => {
          // this.isBusy = false;
          this.$emit("update:isBusy", false);
          if (isRejected) {
            this.fetchPendingChanges();
            this.fetchCompletedChanges();
            this.resetChangeSelection();
            if (this.makerCheckerConfiguration && this.makerCheckerConfiguration.sendEmail) {
              this.sendRejectionEmail(
                _.assign(this.rejectConfirmationForm, {
                  customListOfChanges: listOfChanges,
                })
              );
            }
          } else {
            this.$message({
              type: "error",
              message: "Encountered error rejecting changes.",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error rejecting changes.",
          });

          // this.isBusy = false;
          this.$emit("update:isBusy", false);
        });
    },

    sendRejectionEmail(payload) {
      this.$store
        .dispatch("SEND_REJECTION_EMAIL", payload)
        .then((sent) => {
          if (sent) {
            this.$message({
              type: "success",
              message: "Changes has been rejected successfully. Email has been sent.",
            });
          } else {
            this.$message({
              type: "error",
              message: "Failed to reject changes in server. No email was been sent.",
            });
          }
        })
        .catch((err) => {
          this.$message({
            type: "error",
            message: "Encountered error sending rejection email. No email was sent.",
          });
        });
    },

    dataUserFilter(type, department) {
      const dependenceMap = this.dependenceMap;
      if (department === "all") {
        const filteredData = this.getUniqueChangeTypesOfAuthorisedDepartments(
          this.pendingChangesList,
          this.filteredDepartments
        );
        return _.chain(filteredData)
          .filter((change) => {
            return change.mainType === type;
          })
          .map((change) => {
            return change.modified_by;
          })
          .uniq()
          .value()
          .join(";");
      }
      if (_.get(this, "multipleSelection.length") === 0) {
        return [];
      }

      return _.chain(this.multipleSelection)
        .map((change) => {
          let tempArray = [];
          if (dependenceMap.has(change.RowKey)) {
            const dependentChange = dependenceMap.get(change.RowKey);
            const dependence = dependentChange.dependence;

            tempArray.push(this.changesMap.get(change.RowKey)); // Push the latest change
            dependence &&
              dependence.map((rowKey) => {
                tempArray.push(this.changesMap.get(rowKey)); // Push other previous changes of same ID
              });
          }
          return tempArray;
        })
        .flattenDeep()
        .orderBy(["last_modified"], ["desc"])
        .map((change) => {
          return change.modified_by;
        })
        .uniq()
        .value()
        .join(";");
    },
  },
  mounted() {},
});
</script>

<style scoped lang="scss">
@import "../../../assets/scss/colors.scss";

.rightPane h1 {
  text-align: left;
}

.el-rightPane-cell {
  padding: 0;
}

.changesTable {
  width: 100%;
  font-size: 14px;
}

.changesTable tr th {
  width: 50%;
  text-align: center;
}
.changesTable tr td {
  width: 50%;
  max-width: 300px;
  text-align: left;
  vertical-align: top;
}

.button {
  font-size: 14px;
  /* line-height: 20px; */
  text-align: center;
  background: white !important;
  color: $color-dark !important;
  border: 1px solid $color-light;
}

.button:hover {
  font-size: 14px;
  background: white !important;
  color: $color-dark !important;
  border: 1px solid $color-light;
}

.approve.button {
  font-size: 14px;
  color: #fff !important;
  background-color: $color-success !important;
  border-color: $color-success !important;
}

.approve.button:hover {
  font-size: 14px;
  color: $color-success !important;
  background-color: white !important;
  border-color: $color-success !important;
}
.dot.old {
  height: 10px;
  width: 10px;
  margin-right: 5px;
  background-color: $color-danger;
  border-radius: 50%;
  display: inline-block;
}
.dot.new {
  height: 10px;
  width: 10px;
  margin-right: 5px;
  background-color: $color-success;
  border-radius: 50%;
  display: inline-block;
}

.tableSelection > div > label > span > span.el-checkbox__inner {
  /* to update this to change checkbox color to blue */
  background-color: $color-info !important;
  border-color: $color-info !important;
}

.headerDepartment {
  font-size: 16px;
}

.dialog-condition-table .dialog-condition-table-operator {
  width: 5%;
  text-align: center;
}
.dialog-condition-table .dialog-condition-table-text {
  text-align: center;
}

#dialog-context-table .dialog-context-table-variable {
  width: 40%;
  text-align: center;
}
#dialog-context-table .dialog-context-table-value {
  text-align: center;
}
#dialog-response-table .dialog-response-table-type {
  width: 5%;
  text-align: center;
}
.el-table .warning-row {
  background: oldlace;
}
.tab-panel {
  height: 380px;
}
</style>

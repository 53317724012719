<template>
  <div>
    <el-dialog
      :destroy-on-close="true"
      :visible.sync="dialogVisible"
      width="40%"
      :before-close="handleClose"
    >
      <template slot="title">
        <div class="plugin-modal-header">
          <div class="plugin-info">
            <div class="plugin-img">
              <img :src="customizeIcon" alt="" />
            </div>
            <div class="plugin-name">
              <p>Custom Plugin</p>
              <span
                >Connect an OpenAI Plugin to expose Chat KeyReply to third-party applications.</span
              >
            </div>
          </div>
        </div>
      </template>
      <div class="plugin-modal-body">
        <div class="plugin-modal-configuration">
          <div>
            <p style="font-size: 16px; margin-bottom: 10px"><b>Plugin name</b></p>
            <el-input placeholder="Your Plugin name" v-model="tempConfig.name" />
          </div>
        </div>
        <div class="plugin-modal-configuration">
          <p style="font-size: 16px; margin-bottom: 10px"><b>Plugin File</b></p>
          <PluginUpload
            @updateFileUrl="handleUpdateFileUrl"
            :selectedPlugin="{
              config: tempConfig,
            }"
          />
          <div v-if="tempConfig.fileUrl">
            <div>
              <p><b>JSON file URL</b></p>
            </div>
            <el-input v-model="tempConfig.fileUrl" disabled />
          </div>
        </div>
        <div>
          <div>
            <div @click="downloadPluginTemplate" class="template-download" style="cursor: pointer">
              <i class="el-icon-download" style="margin-right: 5px"></i>
              <span>Example Plugin Template</span>
            </div>
          </div>
        </div>
        <div class="plugin-modal-configuration">
          <p style="font-size: 16px; margin-bottom: 10px">
            <b>Authentication</b>
          </p>
          <!-- Radio with these value (none, bearer, basic, xapikey) -->
          <el-radio-group v-model="tempConfig.authentication.type">
            <el-radio label="none">None</el-radio>
            <el-radio label="bearer">Bearer</el-radio>
            <el-radio label="basic">Basic</el-radio>
            <el-radio label="xapikey">X-API-Key</el-radio>
          </el-radio-group>

          <div style="margin-top: 10px">
            <div v-if="tempConfig.authentication.type === 'bearer'">
              <label for="custom-plugin-token" style="display: block; margin-bottom: 5px"
                >Bearer Token</label
              >
              <el-input
                id="custom-plugin-token"
                placeholder="Bearer token"
                v-model="tempConfig.authentication.token"
                type="text"
              ></el-input>
            </div>

            <div v-else-if="tempConfig.authentication.type === 'basic'">
              <label for="custom-plugin-username" style="display: block; margin-bottom: 5px"
                >Username</label
              >
              <el-input
                id="custom-plugin-username"
                placeholder="Enter your username"
                v-model="tempConfig.authentication.username"
                type="text"
              ></el-input>

              <label
                for="custom-plugin-password"
                style="display: block; margin-top: 10px; margin-bottom: 5px"
                >Password</label
              >
              <el-input
                id="custom-plugin-password"
                placeholder="Enter your password"
                v-model="tempConfig.authentication.password"
                type="password"
                style="margin-top: 10px"
              ></el-input>
            </div>

            <div v-else-if="tempConfig.authentication.type === 'xapikey'">
              <label for="custom-plugin-xapikey" style="display: block; margin-bottom: 5px"
                >X-API-Key</label
              >
              <el-input
                id="custom-plugin-xapikey"
                placeholder="Enter your X-API-Key"
                v-model="tempConfig.authentication.xapikey"
                type="text"
              ></el-input>
            </div>
          </div>
        </div>
      </div>
      <template slot="footer">
        <div>
          <el-button type="plain" @click="handleClose">Cancel</el-button>
          <el-button v-if="modalMode === 'edit'" type="primary" @click="handleConfirm"
            >Confirm</el-button
          >
          <el-button v-else type="primary" @click="handleAddPlugin">Add</el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import customizeIcon from "./assets/icons/customize.png";
import PluginUpload from "./PluginUpload.vue";
import plugin_template from "./plugin_template.json";

export default {
  components: {
    PluginUpload,
  },
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
    selectedPlugin: {
      type: Object,
      default: () => {},
    },
    currentCustomPlugins: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      customizeIcon,
      tempConfig: {
        name: "",
        fileUrl: "",
      },
      modalMode: "create",
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
    handleUpdateFileUrl(fileUrl) {
      this.tempConfig.fileUrl = fileUrl;
    },
    handleConfirm() {
      if (!this.checkPluginName(this.tempConfig.name)) {
        return;
      }

      this.$confirm("Do you want to save the current settings ?", "Warning", {
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        type: "warning",
      }).then(() => {
        this.$emit("updatePluginConfig", this.tempConfig);
        this.$success("Settings saved successfully");
      });
    },
    downloadPluginTemplate() {
      const jsonString = JSON.stringify(plugin_template);
      const blob = new Blob([jsonString], { type: "application/json" });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = "Example.json";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    handleAddPlugin() {
      if (!this.checkPluginName(this.tempConfig.name)) {
        return;
      }
      this.$emit("addCustomPlugin", this.tempConfig);
      this.$emit("updateDialogVisible", false);
    },
    checkPluginName(name) {
      if (!name) {
        this.$notify.error({
          title: "Error",
          message: "Plugin name is required",
        });
        return false;
      }

      // if new plugin name different from the current plugin name
      if (this.selectedPlugin.config.name !== name) {
        if (
          this.currentCustomPlugins.some(
            (plugin) => plugin.config.name.toLowerCase() === name.toLowerCase()
          )
        ) {
          this.$notify.error({
            title: "Error",
            message: "Plugin name already exists",
          });
          return false;
        }
      }
      return true;
    },
  },
  created() {
    if (this.selectedPlugin) {
      this.tempConfig = _.cloneDeep(this.selectedPlugin.config);

      if (!this.tempConfig.authentication) {
        this.tempConfig.authentication = {
          type: "none",
          token: "",
          username: "",
          password: "",
          xapikey: "",
        };
      }

      if (this.tempConfig.name) {
        this.modalMode = "edit";
      }
    }
  },
};
</script>

<style lang="scss">
@import "./assets/css/modal.scss";

.template-download:hover {
  color: #409eff;
}
</style>

<template>
  <el-row
    type="flex"
    justify="flex-start"
    style="margin-bottom: 0; flex-wrap: wrap; align-items: flex-start"
  >
    <el-input
      v-model="searchTerm"
      placeholder="Input search term..."
      size="mini"
      style="margin-right: 10px; margin-bottom: 10px; max-width: 385px"
    >
      <template slot="append">
        <el-popover placement="bottom" title="Filter" trigger="click" width="300">
          <div>
            <!-- <el-select
              size="mini"
              v-model="searchTags"
              multiple
              placeholder="Filter by tag name"
              style="width:100%; margin-bottom: 10px;"
            >
              <el-option
                v-for="(tag, index) in monitorChatTags"
                :key="index"
                :label="tag"
                :value="tag"
              />
            </el-select>-->

            <el-select
              filterable
              clearable
              multiple
              v-model="searchDepartments"
              size="mini"
              placeholder="Filter by my departments"
              style="width: 100%; margin-bottom: 10px; position: relative"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="(department, index) in userDepartments"
                :key="index"
                :label="department"
                :value="department"
              />
            </el-select>

            <el-select
              v-model="searchChatSources"
              placeholder="Filter by chat source"
              multiple
              filterable
              style="width: 100%; margin-bottom: 10px; position: relative"
              size="mini"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="source in chatSources"
                :key="source.value"
                :label="source.displayName"
                :value="source.value"
              ></el-option>
            </el-select>

            <el-select
              filterable
              clearable
              multiple
              v-model="searchContextHistory"
              size="mini"
              placeholder="Filter by content node"
              style="width: 100%; position: relative"
              :popper-append-to-body="false"
            >
              <el-option
                v-for="(contentNode, index) in contentNodeNames"
                :key="index"
                :label="contentNode"
                :value="contentNode"
              />
            </el-select>
          </div>
          <el-button
            :disabled="fetchMonitorChatLoading"
            slot="reference"
            size="mini"
            icon="el-icon-circle-plus-outline"
          >
            Filter
          </el-button>
        </el-popover>
      </template>
    </el-input>

    <PredefinedFilter
      ref="predefinedFilter"
      type="monitorChat"
      style="margin-right: 10px"
      @selectedFilter="onPredefinedFilterSelected"
      @resetFilter="onResetHandler"
    />

    <el-date-picker
      v-model="searchChatDateRange"
      type="datetimerange"
      format="dd/MM/yyyy HH:mm"
      :default-time="['00:00:00', '23:59:59']"
      style="margin-right: 10px; width: 300px; margin-bottom: 10px"
      align="right"
      unlink-panels
      range-separator=" ~ "
      start-placeholder="Start date"
      size="mini"
      end-placeholder="End date"
      :clearable="false"
    ></el-date-picker>

    <el-button
      :disabled="fetchMonitorChatLoading"
      type="primary"
      plain
      size="mini"
      icon="el-icon-search"
      @click="onSearchHandler"
      >Search</el-button
    >

    <el-button
      :disabled="fetchMonitorChatLoading"
      icon="el-icon-close"
      size="mini"
      @click="onResetHandler"
      >Clear</el-button
    >

    <el-button
      :disabled="fetchMonitorChatLoading"
      :loading="fetchMonitorChatLoading"
      type="primary"
      size="mini"
      icon="el-icon-refresh"
      style="margin-right: 10px"
      @click="refreshData"
      >Refresh Data</el-button
    >

    <el-dropdown size="mini" :disabled="fetchMonitorChatLoading || sendEmailLoading">
      <el-button type="info" plain size="mini">
        More
        <i class="el-icon-arrow-down el-icon--right"></i>
      </el-button>
      <el-dropdown-menu slot="dropdown">
        <el-dropdown-item @click.native="emailChat">
          <i class="el-icon-s-promotion"></i>&nbsp;Email Chat Transcript
        </el-dropdown-item>
        <el-dropdown-item @click.native="exportDialogVisible = true">
          <i class="el-icon-download"></i>&nbsp;Download All Chats
        </el-dropdown-item>
        <el-dropdown-item @click.native="exportChatList()">
          <i class="el-icon-download"></i>&nbsp;Download Chat List
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>

    <el-dialog
      id="livechat_downloadAllChats"
      title="Download All Chats"
      :visible.sync="exportDialogVisible"
      :before-close="beforeExportDialogClose"
      width="50%"
    >
      <div element-loading-text="Exporting chats, it might take awhile..." v-loading="isExporting">
        <div width="100%">
          <span>Please select how you would like to export the chats?</span>
        </div>
        <br />
        <div v-if="exportChoice === 'zip'">
          <img src="/template/zipsample1.gif" width="100%" />
          <span>
            This export type will seperate each conversation into one .csv file, combining all of
            them into one compressed .zip file
          </span>
        </div>
        <div v-if="exportChoice === 'single'">
          <img src="/template/singlesample1.gif" width="100%" />
          <span>
            This export type will separate each conversation into different sheets, combining all of
            them into one .xlsx file
          </span>
        </div>
        <el-alert
          v-if="!includeInteractions"
          title="This will not include chat interactions in downloadable file"
          type="warning"
          show-icon
          :closable="false"
        >
        </el-alert>
        <div width="100%">
          <el-radio v-model="exportChoice" label="zip">A .zip file (multiple .csv)</el-radio>
          <el-radio v-model="exportChoice" label="single">An .xlsx file (multiple sheets)</el-radio>
        </div>
      </div>
      <template slot="footer">
        <el-button :disabled="isExporting" type="primary" @click="exportAllChats(exportChoice)">
          Confirm
        </el-button>
        <el-button :disabled="isExporting" @click="closeDialog()">Cancel</el-button>
      </template>
    </el-dialog>
  </el-row>
</template>

<script>
import { v4 as uuid, NIL as NIL_UUID } from "uuid";
import _ from "lodash";
import moment from "moment";

import { mapGetters } from "vuex";
import {
  getDefaultDateRange,
  isDateRangeValid,
  showDateRangeError,
} from "@/components/LiveChat/helpers/filters";
import PredefinedFilter from "@/components/LiveChat/PredefinedFilter";
import { livechatExportMixin } from "@/mixins/liveChat";

export default {
  components: { PredefinedFilter },
  props: ["selectedChat"],
  mixins: [livechatExportMixin],
  data() {
    return {
      chatStatus: "all",
      isSearching: false,
      searchTerm: "",
      searchChatSources: [],
      searchTags: [],
      searchContextHistory: [],
      searchDepartments: [],
      searchChatLimit: 20,
      searchChatDate: new Date(),
      searchChatDateRange: [...this.getDefaultDateRange()],
      exportDialogVisible: false,
      exportChoice: "zip",
      isExporting: false,
      includeInteractions: true,
    };
  },
  computed: {
    ...mapGetters([
      "dataCollectionFormEnabled",
      "monitorChatFilters",
      "fetchMonitorChatLoading",
      "monitorChatTags",
      "monitorChatsArray",
      "selectedChatId",
      "sendEmailLoading",
      "contentNodeNames",
      "agentAllowedQueues",
    ]),
    userDepartments() {
      return this.agentAllowedQueues;
    },
  },
  watch: {
    searchChatDateRange(newValue, prevValue) {
      const noChanges =
        moment(newValue?.[0]).isSame(moment(prevValue?.[0])) &&
        moment(newValue?.[1]).isSame(moment(prevValue?.[1]));
      if (noChanges) {
        return;
      }

      // for range validation
      const [startDate, endDate] = newValue || [];
      const dateRangeValid = this.isDateRangeValid({ startDate, endDate }, 14);
      if (!dateRangeValid) {
        // revert date range
        this.searchChatDateRange = prevValue;

        this.$notify.warning({
          title: "Info",
          position: "bottom-right",
          message: `Date range should not be greater than 14 days`,
        });
      }
    },
  },
  methods: {
    getDefaultDateRange,
    isDateRangeValid,
    refreshData() {
      this.onSearchHandler();
    },
    exportChatList() {
      this.exportDialogVisible = true;
      this.includeInteractions = false;
    },
    closeDialog() {
      this.exportDialogVisible = false;
      if (!this.includeInteractions) {
        this.includeInteractions = true;
      }
    },
    emailChat() {
      this.$set(this.$store.state.livechat, "sendEmailLoading", true);
      this.sendingEmail = true;
      const chat = this.selectedChat;
      const agent_email = this.$store.state.profile.email;
      if (chat) {
        this.$store
          .dispatch("EMAIL_CHAT_TRANSCRIPT", {
            userId: chat.RowKey,
            partitionKey: chat.PartitionKey,
            channel: chat.channel,
            agentEmail: agent_email,
          })
          .then((response) => {
            this.$set(this.$store.state.livechat, "sendEmailLoading", false);
            const message = response.message;
            if (response.type === "info") {
              this.$notify.info({
                title: "Info",
                message: message,
              });
            } else if (response.type === "error") {
              this.$notify.error({
                title: "Error",
                position: "bottom-right",
                message: message,
              });
            } else {
              this.$notify.success({
                title: "Success",
                message: "Chat transcript has been emailed successfully.",
                position: "bottom-right",
              });
            }
          })
          .catch((error) => {
            this.$set(this.$store.state.livechat, "sendEmailLoading", false);
            this.$notify.error({
              title: "Error",
              position: "bottom-right",
              message: "Error sending email.",
            });
          });
      }
    },
    onSearchFunction(monitorChatFilter) {
      this.$emit("setHasNoMoreChats", false);
      this.$store.commit("FLUSH_MONITOR_CHATS_ARRAY"); // remove array of chats from state
      this.$store.commit("SET_FETCH_MONITOR_CHAT_LOADING", true); // apollo.then will set to false when fetch is complete
      this.$store.commit("UPDATE_MONITOR_LIVECHAT_FILTERS", monitorChatFilter);
    },
    onSearchHandler(e) {
      this.$emit("actionTriggered");

      this.$store.commit("SELECT_MONITOR_CHAT", {
        partitionKey: null,
        rowKey: null,
        type: "monitor",
      });

      const monitorChatFilter = this.getSearchFilters();

      if (!this.$store.state.showAdvanced && !this.isDateRangeValid(monitorChatFilter, 14)) {
        showDateRangeError();
        return;
      }

      if (this.$store.state.showAdvanced && !this.isDateRangeValid(monitorChatFilter, 14)) {
        this.$confirm("Are you sure to search in date range more than 14 days?", "Warning", {
          confirmButtonText: "Yes",
          cancelButtonText: "Cancel",
          type: "warning",
        })
          .then(() => {
            this.onSearchFunction(monitorChatFilter);
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "Searching process canceled.",
            });
          });
      } else {
        this.onSearchFunction(monitorChatFilter);
      }
    },
    onResetHandler(e) {
      this.$emit("actionTriggered");

      this.$store.commit("SET_FETCH_MONITOR_CHAT_LOADING", true); // apollo.then will set to false when fetch is complete
      this.$store.commit("FLUSH_MONITOR_CHATS_ARRAY"); // remove array of chats from state
      this.resetFilters();
      const monitorChatFilter = this.getSearchFilters();
      this.$emit("setHasNoMoreChats", false);
      this.$store.commit("UPDATE_MONITOR_LIVECHAT_FILTERS", monitorChatFilter);
    },
    getSearchFiltersCommon() {
      const commonFilters = this.searchFiltersCommon;
      const departmentObj = _.get(this.$store, "state.department.departments", []);
      const departments = this.searchDepartments
        ?.map(
          (v) =>
            `department:${_.chain(departmentObj).find(["name", v]).get("id", NIL_UUID).value()}`
        )
        .join(",");
      const searchTerm = departments
        ? `${this.searchTerm ? `${this.searchTerm},` : ""}${departments}`
        : `${this.searchTerm}`;

      const isRestrictCrossDepartmentEnable =
        this.$store.state.modules.handover.restrictCrossDepartmentAccess;
      const departmentIDs = isRestrictCrossDepartmentEnable
        ? this.userDepartments?.map((v) =>
            _.chain(departmentObj).find(["name", v]).get("id", NIL_UUID).value()
          ) || []
        : [];
      return {
        ...commonFilters,
        departments: departmentIDs,
        searchTerm,
        date: this.searchChatDate,
        dates: [this.searchChatDateRange?.[0], this.searchChatDateRange?.[1]],
        contextHistory: this.searchContextHistory && this.searchContextHistory.join(","),
      };
    },
    getSearchFilters() {
      const commonFilters = this.getSearchFiltersCommon();
      return {
        ...commonFilters,
        limit: parseInt(this.searchChatLimit),
        offset: 0,
        randomNumber: uuid(),
        messageLimit: 15,
      };
    },
    resetFilters() {
      this.searchChatDate = new Date();
      this.searchChatDateRange = this.getDefaultDateRange();
      this.searchChatLimit = 20;
      this.searchTerm = "";
      this.searchChatSources = [];
      this.searchDepartments = [];
      this.searchTags = [];
      this.searchContextHistory = [];
      this.$refs.predefinedFilter.reset();
    },
    beforeExportDialogClose(done) {
      if (!this.isExporting) {
        done();
      }
    },
    exportAllChatsFetch(limit, offset) {
      const commonFilters = this.getSearchFilters();
      const filter = _.cloneDeep(commonFilters);
      return this.$store.dispatch("FETCH_ALL_INTERACTIONS", {
        ..._.omit(filter, "randomNumber"),
        limit,
        offset,
        messageLimit: 200,
        includeInteractions: this.includeInteractions,
      });
    },
    onPredefinedFilterSelected(filter) {
      if (filter.startDate && filter.endDate) {
        this.searchChatDateRange = [filter.startDate, filter.endDate];
      }
      this.searchTerm = filter?.searchTerm || "";
      this.searchContextHistory = _.compact(filter?.contextHistory?.split(",") || []);
      this.searchChatSources = _.compact(filter?.chatSources?.split(",") || []);
      this.onSearchHandler();
    },
  },
};
</script>

<style scoped lang="scss">
.filterGroup .el-select {
  width: 100%;
}
.downloadChats {
  text-align: right;
  padding: 5px 5px;
}
::v-deep .popper__arrow {
  left: 65% !important;
}
</style>

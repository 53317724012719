import { render, staticRenderFns } from "./ChangesOverview.vue?vue&type=template&id=2faa6e76&scoped=true&"
import script from "./ChangesOverview.vue?vue&type=script&lang=js&"
export * from "./ChangesOverview.vue?vue&type=script&lang=js&"
import style0 from "./ChangesOverview.vue?vue&type=style&index=0&id=2faa6e76&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2faa6e76",
  null
  
)

export default component.exports
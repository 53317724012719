<template>
  <div class="changes-container">
    <el-tabs v-model="activeName">
      <el-tab-pane label="Changes" name="default"></el-tab-pane>
      <el-tab-pane label="Advanced" name="json"></el-tab-pane>
    </el-tabs>
    <div v-if="havingChanges" class="changes-wrapper">
      <div v-if="activeName === 'default'">
        <p
          v-for="(change, index) in getChanges()"
          :key="index"
          :style="{
            color: change.color,
          }"
        >
          {{ change.path + " " + change.value }}
        </p>
      </div>
      <div v-else-if="activeName === 'json'" v-html="getChangesAsJson()"></div>
    </div>
    <div
      v-else
      class="changes-wrapper"
      style="place-content: center; place-items: center; display: grid"
    >
      No changes detected
    </div>
  </div>
</template>

<script>
// @ts-nocheck
import * as HumanDiff from "human-object-diff";
import * as jsondiffpatch from "jsondiffpatch/dist/jsondiffpatch.umd";
import _ from "lodash";

const HDiff = new HumanDiff({
  objectName: "$",
  templates: {
    N: `DOTPATH|added NEWVALUE`,
    D: `DOTPATH|removed OLDVALUE`,
    E: `DOTPATH|changed OLDVALUE to NEWVALUE`,
    I: `DOTPATH|added NEWVALUE`,
    R: `DOTPATH|removed OLDVALUE`,
    NS: `DOTPATH|added`,
    DS: `DOTPATH|removed`,
    ES: `DOTPATH|changed`,
    IS: `DOTPATH|added a value`,
    RS: `DOTPATH|removed a value`,
    AES: `DOTPATH|changed a value`,
  },
});

export default {
  name: "JsonDiff",
  data: () => ({
    activeName: "default",
  }),
  props: {
    oldValue: {
      type: Object,
      required: true,
    },
    newValue: {
      type: Object,
      required: true,
    },
  },
  methods: {
    getChanges() {
      let differences = HDiff.diff(this.oldValue, this.newValue);
      differences = _.map(differences, (change) => {
        const index = change.indexOf("|");
        const path = change.substring(0, index);
        const value = change.substring(index + 1);
        let type = "changed";

        if (change.includes("added")) {
          type = "added";
        } else if (change.includes("removed")) {
          type = "removed";
        } else if (change.includes("changed")) {
          type = "changed";
        }

        const color = {
          added: "green",
          removed: "red",
          changed: "orange",
        }[type];

        return {
          path,
          value,
          color,
        };
      });
      return differences;
    },
    getChangesAsJson() {
      jsondiffpatch.formatters.html.hideUnchanged();
      const delta = jsondiffpatch.diff(this.oldValue, this.newValue);
      const changes = jsondiffpatch.formatters.html.format(delta, this.oldValue);
      return changes;
    },
  },
  computed: {
    havingChanges() {
      return !_.isEqual(this.oldValue, this.newValue);
    },
  },
};
</script>

<style scoped lang="scss">
@import "~jsondiffpatch/dist/formatters-styles/html.css";

.changes-container {
  overflow: auto;
  display: grid;
  grid-template-columns: auto;
  background-color: #fff;
  height: fit-content;

  .changes-wrapper {
    margin: 20px;
    padding: 10px;
    border: 2px solid rgba(0, 0, 0, 0.05);
    background: #fdfdfd;
    border-radius: 4px;
    overflow: auto;
  }
}
</style>

<style>
.changes-container .el-tabs__content {
  position: static !important;
}
</style>

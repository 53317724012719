import { LivechatState } from "./types";
import { getEndDate, getStartDate } from "@/components/LiveChat/helpers/filters";

const state: LivechatState = {
  connectedAgents: [], // Agents that connected to dashboard included offline status.
  agentStatus: "OFFLINE",
  socketConnection: "",
  activeTabName: "queue",
  livechats: [],

  queueChatObject: {},
  queueChatCount: 0,
  ongoingChatCount: 0,
  unfilteredResolvedChatsArray: [],
  unfilteredAbandonedChatsArray: [],
  resolvedChatsArray: [],
  resolvedChatCount: 0,
  abandonedChatsArray: [],
  abandonedChatCount: 0,
  monitorChatsArray: [],

  selectedChatId: "", // chat.RowKey
  selectedChat: null,
  fetchQueueChatLoading: true,
  fetchResolvedChatLoading: true,
  fetchAbandonedChatLoading: true,
  fetchMonitorChatLoading: true,
  sendEmailLoading: false,

  queueChatFilters: {
    searchTerm: "",
    chatSources: [],
  },
  resolvedChatFilters: {
    sessionId: "",
    date: new Date(),
    startDate: getStartDate().toDate(),
    endDate: getEndDate().toDate(),
    limit: 20,
    searchTerm: "",
    contextHistory: "",
    tags: [],
    chatSources: [],
    offset: 0,
    messageLimit: 15,
    involvedMe: false,
    agentIsNot: "[]",
    includeInteractions: false,
  },
  abandonedChatFilters: {
    sessionId: "",
    date: new Date(),
    startDate: getStartDate().toDate(),
    endDate: getEndDate().toDate(),
    limit: 20,
    searchTerm: "",
    contextHistory: "",
    tags: [],
    chatSources: [],
    offset: 0,
    messageLimit: 15,
    agentIs: "[]",
    includeInteractions: false,
  },
  monitorChatFilters: {
    sessionId: "",
    date: new Date(),
    startDate: getStartDate().toDate(),
    endDate: getEndDate().toDate(),
    limit: 20,
    searchTerm: "",
    contextHistory: "",
    tags: [],
    chatSources: [],
    offset: 0,
    messageLimit: 15,
    departments: [],
  },
  defaultFilters: {
    sessionId: "",
    date: new Date(),
    // startDate: moment()
    //   .subtract(7, "days")
    //   .toDate(), // default query for queue will be 7 days
    // endDate: moment().toDate(),
    limit: 20,
    searchTerm: "",
    contextHistory: "",
    tags: [],
    chatSources: [],
    offset: 0,
    messageLimit: 15,
  },
  fieldData: {},
  selectedMonitorChatPartitionKey: "",
  selectedMonitorChatRowKey: "",
  typingIndicatorUserIds: {},
  reply: {
    id: null, // null means no reply set (hidden), no need isShow property
    key_id: null,
    type: null,
    from: null,
    previewContent: "",
  },
};

export default state;

<template>
  <div>
    <el-dialog
      title="Upload Document"
      :visible.sync="uploadDialogVisible"
      width="40%"
      :before-close="handleClose"
      id="upload-kb-dialog"
    >
      <el-tabs v-model="activeUploadType" @tab-click="handleChangeUploadType">
        <el-tab-pane label="File" name="file">
          <el-form ref="fileForm" label-position="top">
            <el-form-item label="Category" v-if="document_tags.length > 0">
              <div v-for="(item, index) in document_tags" :key="index">
                <el-checkbox
                  :label="item.name"
                  @change="handleTagChange(item.name, $event)"
                ></el-checkbox>
                <div v-if="isSelectedTagContains(item.name)">
                  <el-select
                    placeholder="Select tags..."
                    multiple=""
                    v-model="selectedTags[item.name]"
                  >
                    <el-option
                      v-for="(tag, index) in item.tags"
                      :key="index"
                      :label="tag"
                      :value="tag"
                    ></el-option>
                  </el-select>
                </div>
              </div>
              <div>
                <el-button size="mini" type="primary" icon="el-icon-plus" @click="handleAddTag"
                  >New Tag</el-button
                >
                <div class="tag-wrapper" v-for="(kb_tag, index) in newTags" :key="index">
                  <el-form label-position="top">
                    <el-form-item>
                      <div style="display: flex; justify-content: space-between">
                        <div>Category</div>
                        <div>
                          <el-button
                            style="margin-left: auto"
                            type="danger"
                            size="mini"
                            icon="el-icon-delete"
                            circle
                            @click.prevent="handleDeleteTagObject(index)"
                          ></el-button>
                        </div>
                      </div>
                      <el-input
                        v-model="kb_tag.name"
                        placeholder="Tag Category"
                        @input="
                          (val) => (kb_tag.name = kb_tag.name.toLowerCase().replace(/\s/g, '_'))
                        "
                      />
                    </el-form-item>
                    <el-form-item label="Tags">
                      <el-tag
                        :key="index"
                        v-for="(tag, index) in kb_tag.tags"
                        closable
                        :disable-transitions="false"
                        @close="handleCloseTag(kb_tag, index)"
                        style="margin-left: 10px"
                      >
                        {{ tag }}
                      </el-tag>
                      <el-input
                        class="input-new-tag"
                        v-if="kb_tag.inputVisible"
                        v-model="kb_tag.newTagValue"
                        size="mini"
                        @keyup.enter.native="handleInputConfirm(kb_tag)"
                        @input="(val) => (kb_tag.newTagValue = kb_tag.newTagValue.toLowerCase())"
                        @blur="handleInputConfirm(kb_tag)"
                      >
                      </el-input>
                      <el-button
                        style="margin-left: 10px"
                        v-else
                        class="button-new-tag"
                        size="small"
                        @click="showInput(index)"
                        >+ New Tag</el-button
                      >
                    </el-form-item>
                  </el-form>
                </div>
              </div>
            </el-form-item>
            <el-form-item>
              <p>Select file</p>
              <el-upload
                action="#"
                class="kb-upload"
                drag
                :limit="5"
                :file-list="fileList"
                multiple
                :auto-upload="false"
                :on-change="handleFileChange"
                :on-remove="handleRemoveFile"
              >
                <i class="el-icon-upload"></i>
                <div class="el-upload__text" style="margin-bottom: 0">
                  Drop file here or <em>click to upload</em> <br />
                  File with a size less than 25mb
                </div>
              </el-upload>
            </el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
      <span slot="footer" class="dialog-footer">
        <el-button
          :loading="isUploading"
          :disabled="fileList.length === 0"
          @click="handleUpload"
          type="primary"
          >Upload</el-button
        >
        <el-button @click="handleClose">Cancel</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as _ from "lodash";
export default {
  props: {
    uploadDialogVisible: {
      type: Boolean,
      default: false,
    },
    genai_settings: {
      type: Object,
      default: () => ({}),
    },
    currentFilter: {
      type: String,
      default: "all",
    },
  },
  data() {
    return {
      dialogVisible: false,
      activeUploadType: "file",
      radio: "text",
      file: null,
      fileList: [],
      isUploading: false,
      selectedTags: {},
      newTags: [],
      isError: false,
    };
  },
  methods: {
    handleClose() {
      this.$emit("updateDialogVisible", false);
    },
    handleChangeUploadType(tab, event) {},
    async handleUpload() {
      this.isUploading = true;
      try {
        let selectedTags = _.cloneDeep(this.selectedTags);
        if (this.newTags.length > 0) {
          selectedTags = {
            ...selectedTags,
            ...this.newTags.reduce((acc, item) => {
              const formatTagName = item.name.toLowerCase().replace(/\s/g, "_");
              if (!formatTagName) {
                this.isError = true;
              }
              acc[formatTagName] = item.tags;
              return acc;
            }, {}),
          };
          if (this.isError) {
            this.$notify({
              title: "Error",
              message: "Please input category name",
              type: "error",
            });
            this.isUploading = false;
            this.isError = false;
            return;
          }
          this.saveNewTagToSetting();
        }
        await this.$store.dispatch("GENAI_UPLOAD_KNOWLEDGE", {
          files: this.fileList,
          tags: selectedTags,
          uploadedBy: this.userProfile.email,
        });
        this.handleFinishUpload();
      } catch (error) {
        console.log(error);
        this.$notify({
          title: "Error",
          message: "There was an error uploading the file",
          type: "error",
        });
      }
    },
    handleFileChange(file, fileList) {
      this.fileList = fileList;
    },
    handleRemoveFile(file, fileList) {
      this.fileList = fileList;
    },
    async handleFinishUpload() {
      this.isUploading = false;
      const forceClose = true;
      const dialogVisible = false;
      this.$emit("updateDialogVisible", dialogVisible, forceClose);
      await this.$store.dispatch("GENAI_GET_UPLOADED_DOCUMENTS", {
        page: 1,
        type: this.currentFilter,
      });
      this.$notify({
        title: "Success",
        message: "File uploaded successfully",
        type: "success",
      });
    },
    handleTagChange(itemName, value) {
      if (value) {
        this.$set(this.selectedTags, itemName, []);
      } else {
        this.$delete(this.selectedTags, itemName);
      }
    },
    isSelectedTagContains(tagName) {
      return _.has(this.selectedTags, tagName);
    },
    handleAddTag() {
      this.newTags.push({
        name: "",
        tags: [],
        inputVisible: false,
        newTagValue: "",
      });
    },
    handleDeleteTagObject(index) {
      this.$confirm("Are you sure to delete this category?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      })
        .then(() => {
          this.newTags.splice(index, 1);
        })
        .catch(() => {});
    },
    handleCloseTag(tagObject, index) {
      tagObject.tags.splice(index, 1);
    },
    showInput(index) {
      this.newTags[index].inputVisible = true;
    },

    handleInputConfirm(tagObject) {
      let newTagValue = tagObject.newTagValue;
      if (newTagValue) {
        tagObject.tags.push(newTagValue);
      }
      tagObject.inputVisible = false;
      tagObject.newTagValue = "";
    },
    saveNewTagToSetting() {
      this.saving = true;
      this.modules.genai.knowledge_document.tags = [
        ...this.modules.genai.knowledge_document.tags,
        ...this.newTags,
      ];
      this.$store
        .dispatch("SAVE_MODULES", {
          modules: this.modules,
        })
        .then(
          () => {
            this.saving = false;
            this.$message({
              type: "success",
              message: "Successfully save new tag to setting",
            });
          },
          () => {
            this.saving = false;
            this.$message({
              type: "error",
              message: "Error saving config",
            });
          }
        );
    },
  },
  computed: {
    document_tags() {
      return this.genai_settings?.knowledge_document.tags || [];
    },
    modules: {
      get() {
        return this.$store.state.modules;
      },
      set(value) {
        this.$store.state.modules = value;
      },
    },
    userProfile() {
      return this.$store.state.profile;
    },
  },
};
</script>

<style lang="scss">
#upload-kb-dialog {
  .el-form-item__label {
    width: 100% !important;
  }
}
.kb-upload {
  width: 100% !important;

  .el-upload {
    display: flex;
    align-items: center;
    .el-upload-dragger {
      width: 100%;
      height: 300px;
    }

    .el-icon-upload {
      margin-top: 80px;
    }
  }
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px !important;
  margin-left: 10px !important;
  vertical-align: bottom;
}

.tag-wrapper {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>

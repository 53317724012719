import { GetterTree } from "vuex/types";
import { RootState } from "@/store/types";
import { SupervisorState } from "./types";
import _ from "lodash";

const getters: GetterTree<SupervisorState, RootState> = {
  onlineAgentsCount(_state, getters, _rootState, _rootGetters) {
    return getters.onlineAgentsWithChatCount?.length;
  },
  offlineAgentsCount(_state, getters, _rootState, _rootGetters) {
    return getters.offlineAgentsWithChatCount?.length;
  },
  totalChatsCount(state, getters, _rootState, rootGetters) {
    return (
      state.inQueueChatCount +
      state.onGoingChatCount +
      state.abandonedChatCount +
      state.resolvedChatCount
    );
  },
  showOnlineAgents(state, _getters, _rootState) {
    return state.currentMode === "ONLINE_AGENTS";
  },
  showOfflineAgents(state, _getters, _rootState) {
    return state.currentMode === "OFFLINE_AGENTS";
  },
  selectedAgent(state, _getters, _rootState) {
    return state.selectedAgent;
  },
  selectedChatSession(state, _getters, _rootState) {
    return state.selectedChatSession;
  },
  agentsWithChatCount(state, _getters, _rootState) {
    return state.agentsWithChatCount;
  },
  onlineAgentsWithChatCount(_state, getters, _rootState) {
    return _.filter(
      getters.agentsWithChatCount,
      ({ agent }) => agent.status === "ONLINE" || agent.livechat_status === "ONLINE"
    );
  },
  offlineAgentsWithChatCount(_state, getters, _rootState) {
    return _.filter(
      getters.agentsWithChatCount,
      ({ agent }) => agent.status !== "ONLINE" && agent.livechat_status !== "ONLINE"
    );
  },
};

export default getters;
